<template>
  <div class="fh_register_b">
    <!-- 注册 -->
    <div class="fh_label_f">
      <input
        type="text"
        placeholder="请输入手机号"
        v-model="phone"
        maxlength="11"
      />
    </div>
    <div class="fh_label_f">
      <input
        type="password"
        placeholder="请输入密码8-16位"
        v-model="password"
      />
    </div>
    <div class="fh_label_f">
      <input
        type="text"
        placeholder="输入验证码"
        class="fh_code"
        v-model="inputCode"
      />
      <span class="fh_get" v-show="btnBool" @click="getCode">获取验证码</span>
      <span v-show="!btnBool" class="fh_get">{{ count }} 秒</span>
    </div>
    <p class="fh_read">
      <label class="el-checkbox">
        <el-checkbox v-model="checked">同意接受 </el-checkbox>
      </label>
      <span>
                    <router-link
                      target="_blank"
                      class="blue"
                      :to="{ path: 'member-service' }"
                    >
                      《科汇云会员服务协议》
                    </router-link>
                    <router-link
                      target="_blank"
                      class="blue"
                      :to="{ path: 'trading-service' }"
                    >
                      《科汇云交易服务协议》
                    </router-link>
                    <router-link
                      target="_blank"
                      class="blue"
                      :to="{ path: 'privacy-protection' }"
                    >
                      《隐私保护声明》
                    </router-link>
                  </span>
    </p>
    <el-button type="primary" class="fh_logining" @click="register"
      >注册</el-button
    >
  </div>
</template>
<script>
import { Register, Send_Sms } from "../../../common/js/api";
export default {
  data() {
    return {
      inputCode: "",
      phone: "",
      password: "",
      btnBool: true,
      count: "",
      timer: null,
      checked: false,
      yid: "",
      second: "first",
    };
  },
  created() {},
  methods: {
    // 注册
    register() {
      if (this.checked == false) {
        this.yid = "";
      } else {
        this.yid = 1;
      }
      Register({
        phone: this.phone,
        password: this.password,
        code: this.inputCode,
        yid: this.yid,
      })
        .then((res) => {
          console.log(res);
          if (res.code == "200") {
            this.$message.success(res.msg);
            this.$emit("onChild", this.second);
            this.phone = "";
            this.password = "";
            this.inputCode = "";
            this.yid = "";
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },

    getCode() {
      if (
        this.phone != null &&
        this.phone != "" &&
        /^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(
          this.phone
        )
      ) {
        this.getyzm();
        Send_Sms({
          phone: this.phone,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("验证码发送成功，请注意查收短信");
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch(() => {});
      } else {
        this.$message.error("请输入正确的11位手机号");
      }
    },
    //点击获取验证码
    getyzm() {
      const TIME_COUNT = 60; //倒计时60秒
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.btnBool = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.btnBool = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
  },
};
</script>
 <style scoped src="./login.css"></style>
 