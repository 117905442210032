<template>
  <div>
    <!-- 登录 -->
    <LoginNei></LoginNei>
  </div>
</template>

<script>
import LoginNei from "./base/LoginNei";
export default {
  name: "login",
  data() {
    return {};
  },
  created() {},
  methods: {
    TwoClick(item) {
      //console.log(item);
      this.ConList = item;
    },
  },
  components: {
    LoginNei,
  },
};
</script>

<style scoped lang="less">
/deep/#app .el-container {
  display: none;
}
</style>
