<template>
  <div>
    <div class="fh_register_in" v-show="step">
      <span class="fh_password">找回密码</span>
      <div class="fh_register_b">
        <div class="fh_label_f">
          <input
            type="text"
            placeholder="请输入手机号"
            maxlength="11"
            v-model="phone"
          />
        </div>
        <div class="fh_label_f">
          <input
            type="text"
            placeholder="输入验证码"
            class="fh_code"
            v-model="inputCode"
          />
          <span class="fh_get" v-show="btnBool" @click="getCode"
            >获取验证码</span
          >
          <span v-show="!btnBool" class="fh_get">{{ count }} 秒</span>
        </div>
        <el-button type="primary" class="fh_logining" @click="next"
          >下一步</el-button
        >
      </div>
    </div>
    <div class="fh_register_in" v-show="!step">
      <span class="fh_password">设置密码</span>
      <div class="fh_register_b">
        <div class="fh_label_f">
          <input
            type="password"
            placeholder="请输入新密码"
            v-model="password"
          />
        </div>
        <div class="fh_label_f">
          <input
            type="password"
            placeholder="请再次输入新密码"
            v-model="passwords"
          />
        </div>
        <el-button type="primary" class="fh_logining" @click="wancheng"
          >完成</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Send_Sms, Rlogin, Rlogins } from "../../../common/js/api";
export default {
  name: "Forget",
   data() {
    return {
      inputCode: "",
      phone: "",
      password: "",
      passwords: "",
      btnBool: true,
      count: "",
      pass: true,
      step: true,
      timer: null,
    };
  },
  created() {},
  methods: {
    getCode() {
      if (
        this.phone != null &&
        this.phone != "" &&
        /^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(
          this.phone
        )
      ) {
        this.getyzm();
        Send_Sms({
          phone: this.phone,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("验证码发送成功，请注意查收短信");
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch(() => {});
      } else {
        this.$message.error("请输入正确的11位手机号");
      }
    },
    //点击获取验证码
    getyzm() {7
      const TIME_COUNT = 60; //倒计时60秒
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.btnBool = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.btnBool = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },

    // 忘记密码 下一步
    next() {
      Rlogin({
        phone: this.phone,
        code: this.inputCode,
      })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.step = false;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    // 忘记密码 完成
    wancheng() {
      Rlogins({
        phone: this.phone,
        password: this.password,
        passwords: this.passwords,
      })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.step = true;
            this.pass = true;
            this.$emit("Pass", this.pass);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
  },
};
</script>
 <style scoped src="./login.css"></style>
 
