<template>
  <div>
    <div class="container-login">
      <div class="wrap-all-login">
        <div class="left-tips-box left">
          <div class="logo"><img :src="urlImg + logo" /></div>
          <div class="text">
            <p>科汇云，让技术转移与成果转化变得简单起来。</p>
          </div>
        </div>
        <!-- 登录/注册 -->
        <div class="fh_register right" v-show="pass">
          <el-tabs v-model="activeName">
            <el-tab-pane label="登录" name="first">
              <div class="fh_register_b" style="">
                <div class="fh_label_f">
                  <input
                    type="text"
                    placeholder="请输入手机号"
                    v-model="phone"
                    maxlength="11"
                  />
                </div>
                <div class="fh_label_f" v-show="show">
                  <input
                    type="text"
                    placeholder="输入验证码"
                    class="fh_code"
                    v-model="inputCode"
                  />
                  <span class="fh_get" v-show="btnBool" @click="getCode"
                    >获取验证码</span
                  >
                  <span v-show="!btnBool" class="fh_get">{{ count }} 秒</span>
                </div>
                <div class="fh_label_f" v-show="!show">
                  <input
                    type="password"
                    placeholder="请输入密码"
                    v-model="password"
                  />
                </div>

                <p class="fh_read">
                  登录即表示您同意
                  <span>
                    <router-link
                      target="_blank"
                      class="blue"
                      :to="{ path: 'member-service' }"
                    >
                      《科汇云会员服务协议》
                    </router-link>
                    <router-link
                      target="_blank"
                      class="blue"
                      :to="{ path: 'trading-service' }"
                    >
                      《科汇云交易服务协议》
                    </router-link>
                    <router-link
                      target="_blank"
                      class="blue"
                      :to="{ path: 'privacy-protection' }"
                    >
                      《隐私保护声明》
                    </router-link>
                  </span>
                </p>
                <el-button
                  type="primary"
                  class="fh_logining"
                  @click="Dlogin"
                  v-show="show"
                  >立即登录</el-button
                >
                <el-button
                  type="primary"
                  class="fh_logining"
                  @click="login"
                  v-show="!show"
                  >立即登录</el-button
                >
                <div class="fh_type">
                  <span class="left" @click="zmLogin" v-show="show"
                    >账号密码登录</span
                  >
                  <span class="left" @click="dmLogin" v-show="!show"
                    >动态密码登录</span
                  >
                  <span class="right" @click="wjmm">忘记密码?</span>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="注册" name="second">
              <Register @onChild="onChild"></Register>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 忘记密码 -->
        <div class="fh_register right" v-show="!pass">
          <Forget @Pass="Pass"></Forget>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Register from "./register";
import Forget from "./forget";
import { Send_Sms, Login, DLogin, Logo } from "@/common/js/api";
import { urlImg } from "@/common/js/url";
export default {
  name: "LoginNei",
  components: {
    Register,
    Forget,
  },
  data() {
    return {
      activeName: "first",
      inputCode: "",
      phone: "",
      password: "",
      btnBool: true,
      count: "",
      show: true,
      pass: true,
      step: true,
      timer: null,
      checked: false,
      logo: "",
      urlImg: "",
    };
  },
  created() {
    this.getLogo();
    this.urlImg = urlImg();
  },
  methods: {
    // 获取logo
    getLogo() {
      Logo({})
        .then((res) => {
          this.logo = res.data.logo;
        })
        .catch(() => {});
    },
    onChild(val) {
      this.activeName = val;
    },
    Pass(val) {
      this.pass = val;
    },
    getCode() {
      if (
        this.phone != null &&
        this.phone != "" &&
        /^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(
          this.phone
        )
      ) {
        this.getyzm();
        Send_Sms({
          phone: this.phone,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("验证码发送成功，请注意查收短信");
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch(() => {});
      } else {
        this.$message.error("请输入正确的11位手机号");
      }
    },
    //点击获取验证码
    getyzm() {
      const TIME_COUNT = 60; //倒计时60秒
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.btnBool = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.btnBool = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 账号密码登录
    login() {
      Login({
        phone: this.phone,
        password: this.password,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.$router.push({
              path: "/home",
            });
            localStorage.setItem("user", JSON.stringify(res.data.user));
            window.localStorage.setItem("token", res.data.access_token);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    // 动态密码登录
    Dlogin() {
      DLogin({
        phone: this.phone,
        code: this.inputCode,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.$router.push("/home");
            localStorage.setItem("user", JSON.stringify(res.data.user));
            window.localStorage.setItem("token", res.data.access_token);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    // 账号密码登录
    zmLogin() {
      this.show = false;
    },
    // 动态密码登录
    dmLogin() {
      this.show = true;
    },
    // 忘记密码
    wjmm() {
      this.pass = false;
    },
  },
};
</script>
 <style scoped src="./login.css"></style>
<style scoped lang="less">
#app .nav-bar .nav-top,
.el-container {
  display: none !important;
}
.container-login::before {
  content: "";
  /*-webkit-filter: opacity(50%);
 filter: opacity(50%); */
  background-color: #000;
  opacity: 0.6; //透明度设置
  z-index: -1;
  background-size: 500px 300px;
  width: 100%;
  height: 100%;
  position: absolute;
  //一定要设置position:absolute,这样才能设置z-index，让背景处于内容的下一层
  top: 0px;
  left: 0px;
  border-radius: 40px;
}
.container-login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  background: #f8f9fa;
  background: url(../../../assets/img/home/bg.jpg) 0 0 no-repeat;
  background-size: cover;
  position: relative;
  z-index: 99;
  .wrap-all-login {
    width: 734px;
    height: 485px;
    margin: auto;
    background: #fff;
    .left-tips-box {
      width: 315px;
      height: 100%;
      background: #4470f3;
      padding: 97px 58px 0;
      color: #fff;
      font-size: 12px;
      overflow: hidden;
      line-height: 1.5;
      .logo {
        // font-size: 28px;
        margin-bottom: 30px;
        width: 169px;
        height: 54px;
      }
    }
    .fh_register {
      width: 404px;
      height: 100%;
      padding: 70px 40px 0 54px;
      //   .fh_register_t {
      //     width: 100%;
      //     height: 33px;
      //     line-height: 33px;
      //     margin-bottom: 20px;
      //     text-align: center;
      //   }
      //   .fh_register_t > span {
      //     margin-right: 36px;
      //     display: inline-block;
      //     line-height: 33px;
      //     color: #333;
      //     font-size: 18px;
      //     cursor: pointer;
      //   }
      //   .fh_register_t > span:last-of-type {
      //     margin-right: 0;
      //     margin-left: 36px;
      //   }
      //   .fh_register_t > span.cur {
      //     line-height: 33px;
      //     height: 31px;
      //     border-bottom: 2px solid #2278e9;
      //     color: #2278e9;
      //   }
    }
  }
}
/deep/.el-tabs__nav-scroll {
  padding-left: 80px;
}
/deep/.el-button + .el-button {
  margin-left: 0;
}
// @media screen and (max-width: 1200px) {
// 	.container-login .wrap-all-login{
// 		width: 98%;
// 		display: flex;
// 		align-items: center;
// 	}
// 	.container-login .wrap-all-login .fh_register{
// 		flex-grow: 1;
// 	}
// 	/deep/.el-tabs__nav-scroll{
// 		padding-left: 30%;
// 	}
// 	/deep/.el-tabs__item{
// 		font-size: 1.6rem;
// 	}
// 	.container-login .wrap-all-login .left-tips-box{
// 		font-size: 1.6rem;
// 	}
// 	.fh_label_f>input{
// 		font-size: 1.3rem;
// 	}
// }
</style>